import { postCoachGeneratedEmail } from "api-services/definitions/generated-email";
import { useApi } from "api-services/endpoints";
import axios from "axios";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { getCoachEmails, hasEmail } from "@lib/utils/email";

export interface FormFieldsTypes {
  Subject: string;
  MessageValue?: string;
  body: string;
  fromEmail: string;
}

type UseSharingModalType = (props: { coach: AccountType; oid: string }) => {
  send: (data: FormFieldsTypes, clientEmail: string) => Promise<void>;
  hasEmailIntegrated: boolean;
};

const useSharingModal: UseSharingModalType = ({ coach, oid }) => {
  const hasEmailIntegrated = hasEmail(coach);
  const { apiCall: sendCoachGeneratedEmail } = useApi(postCoachGeneratedEmail, {
    failMode: "throw",
  });

  const sendCourierEmail = (data: FormFieldsTypes, clientEmail: string) => ({
    coach_email: coach.email,
    coach_first_name: displayNameFromContact(coach, true) ?? "",
    coach_full_name: displayNameFromContact(coach) ?? "",
    email_title: data.Subject,
    email_content: data.body,
    client_email: clientEmail,
    coach_id: oid,
  });

  const sendIntegratedEmail = (data: FormFieldsTypes, clientEmail: string) => ({
    email: getCoachEmails(coach)[0],
    body: data.body,
    draft: false,
    subject: data.Subject,
    toEmail: clientEmail,
    fromName: displayNameFromContact(coach),
    fromEmail: data.fromEmail,
  });

  const send = async (data: FormFieldsTypes, clientEmail: string) => {
    if (hasEmailIntegrated) {
      await axios.post(
        `gmail/${oid}/send`,
        sendIntegratedEmail(data, clientEmail)
      );
    } else {
      await sendCoachGeneratedEmail(
        {},
        sendCourierEmail(data, clientEmail),
        {}
      );
    }
  };

  return { send, hasEmailIntegrated };
};

export default useSharingModal;
